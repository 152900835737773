import React from "react";
import "./css_files/style.scss";
import logo from "../Files/logo-light-widget.png";
import { Link } from "react-router-dom";
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import useAuth from "../../hooks/useAuth";
import { InjectedConnector } from "@web3-react/injected-connector";

const Nav = () => {
  const { account } = useWeb3React();
  const { login, logout } = useAuth();

  const connectMetaMask = async () => {
    try {
      console.log(account);
      if (account) {
        logout();
        localStorage.setItem("flag", false);
      } else {
        login("injected");
        localStorage.setItem("connectorId", "injected");
        localStorage.setItem("flag", true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container-fluid navbar_main">
      <nav className="navbar navbar-expand-lg navbar-light py-0">
        <div className="container-fluid">
          <div className="navbar-brand ms-sm-5">
            <Link to="/">
              <img
                src={logo}
                alt=""
                className="img-fluid w-sm-100 float-start"
              />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-md-auto mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link"></a>
              </li>
            </ul>
            <div className="links d-flex align-items-center flex-lg-row flex-column justify-content-md-left justify-content-center p-lg-0 p-3">
              <a
                href="https://pblcnft.com/"
                target="_blank"
                className="lk me-lg-2 ms-lg-4 text-center "
              >
                NFT Staking
              </a>
              <br />
              <a
                href="https://nwo.capital/"
                target="_blank"
                className="lk ms-lg-3 me-lg-5 text-center"
              >
                About Us
              </a>
              <a data-toggle="tooltip" data-placement="bottom" title={account}
                className="lk ms-lg- me-lg-4 mt-3 text-center font-weight-bolder"
              >
                {account &&
                  <p style={{ color: "white" }}>( {account?.slice(0, 4)}...{account?.slice(38, 42)})</p>}
              </a>
            </div>

            <div className="d-flex flex align-items-center justify-content-center connect my-2">
              <div>
                {account ? (
                  <button className="btn btn-primary" onClick={connectMetaMask}>
                    {/* {account.slice(0,15)}... */}
                    Disconnect Wallet
                  </button>
                ) : (
                  <button className="btn btn-primary" onClick={connectMetaMask}>
                    Connect Wallet
                  </button>
                )}
              </div>

            </div>{/* <div>
                {account ?
                  <p style={{ color: "white" }}> {account?.slice(0, 4)}...{account?.slice(38, 42)}</p>
                  :
                  <p style={{ color: "black" }}> address</p>
                }
              </div> */}
          </div>
        </div>
      </nav >
    </div >
  );
};

export default Nav;
