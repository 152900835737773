import React, { useEffect, useState } from "react";
import "./css_files/style.scss";
import { BidBalance } from '../../hooks/balanceOf'
import { StakedAmount } from '../../hooks/stakedAmount'
import { StakedReward } from '../../hooks/stakeReward'
import Approve from '../../hooks/approveAllowance'
import UserStaking from '../../hooks/staking'
import UserUnStaking from '../../hooks/unStaking'
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../hooks/useWeb3";
import Loader from "../../hooks/loader";
import { toast } from "react-toastify";
import environment from '../../utils/Environment'
import { getTokenAbi } from "../../utils/contractHelpers";
const Staking = () => {
  const [show, setshow] = useState(true);
  const [active, setActive] = useState(true);
  const [stakeData, setStakeData] = useState()
  const [tokenBalance, setTokenBalance] = useState(0)
  const [stakeUnstakeRes, setStakeUnstakeRes] = useState(null)
  const [stakeDate, setStakeDate] = useState('0-0-0')
  const [stakeAmount, setStakeAmount] = useState(0)
  const [rewardState, setRewardState] = useState(0)
  const [loader, setLoader] = useState(false)
  const [buttonState, setButtonState] = useState(false)
  const [tab, setTab] = useState('Available')
  let { account } = useWeb3React()
  const { CurrentBlc } = BidBalance()
  const { stakeAmountFun } = StakedAmount()
  const { stakeRewardFun } = StakedReward()
  const { userAppriveAllowance } = Approve()
  const { userStakingBack } = UserStaking()
  const { userUnStakingBack } = UserUnStaking()
  const web3 = useWeb3();
  const handleStake = () => {
    setshow(true);
    setTab('Available')
  };
  const handleUnStake = () => {
    setshow(false);
    setTab('Staked')
  };
  const stake = async () => {
    try {
      setLoader(true)
      let res = await userAppriveAllowance(account, stakeData)
      if (res) {
        let stake = await userStakingBack(stakeData)
        setStakeUnstakeRes(stake)
        // console.log('ddsfasd stake', stake)
        toast.success('Staked Successfully', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      }
      // console.log('ddsfasd', res)
      setLoader(false)
      setStakeData(0)
    } catch (error) {
      setLoader(false)
      // console.log('ddsfasd', error)
      toast.error('Stake Error', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }

  }
  const Unstake = async () => {
    try {
      setLoader(true)
      let unstake = await userUnStakingBack(stakeData)
      setStakeUnstakeRes(unstake)
      setLoader(false)
      setStakeData(0)
      toast.success('UnStake Successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      func();
      stakedAmFun();
    } catch (error) {
      setLoader(false)
      // console.log('ddsfasd unstake', error)
      toast.error('Unstake Error', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }

  }
  const func = async () => {
    // console.log('askdfasl staked amount', account)
    let balance = await CurrentBlc(account)
    // console.log('askdfasl', balance)
    setTokenBalance(balance)
  }
  const stakedAmFun = async () => {
    let balance = await stakeAmountFun(account)
    let StakedAm = web3.utils.fromWei(balance?.stakedAmount?.toString(), 'ether')
    var d = new Date(0)
    d.setUTCSeconds(balance?.depositTime);
    setStakeDate(JSON.stringify(d))
    // const date= new Date(parseFloat(balance?.depositTime))
    // console.log('askdfasl staked amount', balance, d)
    // setStakeData(StakedAm)
    setStakeAmount(parseFloat(StakedAm))
    // setTokenBalance(balance)
  }
  const rewardAmFun = async () => {
    let reward = await stakeRewardFun(account)
    let StakedAm = web3.utils.fromWei(reward?.toString(), 'ether')
    setRewardState(StakedAm)
  }
  const stakeInput = async (e) => {
    console.log("====>", e.target.value);
    if (e.target.value < 0) {
      setStakeData('')
    } else {
      setStakeData(e.target.value)
      // stakeInputt(e.target.value);
    }
  }
  const stakeInputt = async (aa) => {
    console.log("====>", aa);

    const contractAddress = environment.token;
    let stakingAddress = environment.staking
    const contract = getTokenAbi(contractAddress, web3);
    let amount = web3.utils.toWei(aa?.toString(), 'ether');
    let allowance = await contract.methods.allowance(account, stakingAddress).call();
    console.log('ddsfasd=====>', allowance, parseFloat(allowance) < parseFloat(amount))
    if (parseFloat(allowance) < parseFloat(amount)) {
      setButtonState(false)
    } else {
      setButtonState(true)
    }
  }
  const unstakeInput = async (e) => {
    if (e.target.value < 0) {
      setStakeData('')
    } else if (e.target.value > stakeAmount) {
      setStakeData(stakeAmount)
    } else {
      setStakeData(e.target.value)
    }
  }
  useEffect(() => {
    if (account) {
      // console.log('askdfasl staked amount', account)
      func()
      stakedAmFun()
      rewardAmFun()
    }

  }, [account, stakeUnstakeRes])
  // console.log('goerilisos', tokenBalance)
  return (
    <div className="stake_card_da_abo">
      <div className="container stake_card mt-5">

        <div className="row pool">
          <div className="col-md-4 col-12">
            <h2 className="text-white">
              <span>$STATE </span> Staking
            </h2>
          </div>
        </div>
        <div className="row justify-content-lg-between justify-content-center crds_1">
          <div className="col-lg-5 col-sm-10 col-11 left-sideof-card">
            <button
              className={tab === 'Available' ? "btn btn-primary hoverTab" : "btn btn-primary"}
              // style={{ fontSize: {active?("20px"):} }}
              onClick={() => { handleStake(); setStakeData('') }}
            >
              STAKE
            </button>
            <button
              className={tab === 'Staked' ? "btn btn-primary hoverTab" : "btn btn-primary"}
              // style={{ fontSize: active2 }}
              onClick={() => { handleUnStake(); setStakeData('') }}
            >
              UNSTAKE
            </button>
            <div className="col-12 py-3">
              <div className="d-flex justify-content-between">
                <p className="color_gl my-auto">{tab} Amount</p>
                {tab === 'Staked' ?
                  <strong>{stakeAmount + ' $STATE'}</strong>
                  :
                  <p className="pOnEnd my-auto">
                    {/* Available Amount{" "} */}
                    <strong>
                      {" "}
                      <br className="d-sm-none d-block" /> {(parseFloat(tokenBalance) || 0).toFixed(2)} $STATE
                    </strong>
                  </p>
                }
              </div>
              <div class="inputMax">
                <input value={stakeData} onChange={tab === 'Available' ? stakeInput : unstakeInput} type="number" placeholder={`Enter ${tab} amount`} />
                {show && <button onClick={() => setStakeData(tokenBalance)} className="btn-common mx">MAX</button>}
              </div>
            </div>
            {show ? (
              <div className="buttons" data-toggle="tooltip" data-placement="top" title={parseFloat(tokenBalance) <= 0 && 'Insufficent Token Balance'}>

                {/* <button className="btn btn-primary">Approve</button> */}
                {loader ?
                  <button aria-readonly className="btn btn-primary loaderdbtn">
                    <img width={25}
                      style={{
                        filter: 'invert(99%) sepia(49%) saturate(0%) hue-rotate(264deg) brightness(112%) contrast(100%)'
                      }}
                      src="https://v.fastcdn.co/u/430e104e/57579327-0-Loaders-3.svg"
                      alt="loader"
                    />
                  </button>
                  :
                  // (
                  //   buttonState ?
                  <button disabled={!stakeData || parseFloat(stakeData) <= 0 || parseFloat(stakeData) === stakeAmount || parseFloat(tokenBalance) <= 0} onClick={stake} className="btn btn-primary" >Stake</button>
                  //     :
                  //     <button disabled={!stakeData || parseFloat(stakeData) <= 0 || parseFloat(stakeData) === stakeAmount} onClick={stake} className="btn btn-primary">Approve & Stake</button>
                  // )
                }
              </div>
            ) : (
              <>
                <div className="buttons">
                  {loader ?
                    <button aria-readonly className="btn btn-primary loaderdbtn">
                      <img width={25}
                        style={{
                          filter: 'invert(99%) sepia(49%) saturate(0%) hue-rotate(264deg) brightness(112%) contrast(100%)'
                        }}
                        src="https://v.fastcdn.co/u/430e104e/57579327-0-Loaders-3.svg"
                        alt="loader"
                      />
                    </button>
                    :
                    <button disabled={!stakeData || parseFloat(stakeData) <= 0 || parseFloat(stakeData) > stakeAmount} onClick={Unstake} className="btn btn-primary mx-auto">Unstake</button>
                  }
                </div>
              </>
            )}
          </div>
          <div className="col-lg-5 col-sm-10 col-11 sum">
            <h2>SUMMARY</h2>
            <table class="table">
              <tbody>
                <tr>
                  <th scope="row">Stake Date</th>
                  <td>
                    {stakeDate?.slice(1, 11).includes(1970) ? '0-0-0' : stakeDate?.slice(1, 11)}
                    {/* 2022-12-10 12:15 */}
                  </td>
                </tr>
                <tr>
                  <th>APY</th>
                  <td>16.18%</td>
                </tr>
                <tr>
                  <th>Estimated Interests</th>
                  <td>{parseFloat(rewardState)?.toFixed(2)} STATE</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staking;
