import React from "react";
import "./css_files/style.scss";
import logo from "../Files/logo-light-widget.png";
const Footer = () => {
  return (
    <div className="container-fluid foot_outer mt-5">
      <div className="row foot justify-content-around align-items-center py-2 ">
        <div className="col-md-5 col-12 d-flex justify-content-md-start justify-content-center">
          <img src={logo} alt="" className="img-fluid float-start" />
        </div>
        <div className="col-md-5 col-12">
          <p className="text-white text-center text-md-end">
            <span className="d-none d-md-inline">
              {" "}
              <a
                href="https://pblcnft.com/"
                target="_blank"
                className="lk me-sm-4"
              >
                NFT Staking
              </a>
            </span>
            <span className="d-none d-md-inline">
              <a
                href="https://nwo.capital/"
                target="_blank"
                className="lk me-sm-4"
              >
                About Us
              </a>
            </span>
            © 2021 STATEGov.wtf All Right Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
