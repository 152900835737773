import React from "react";
import { Link } from "react-router-dom";
import "./css_files/style.scss";
import index_im from "../Files/Final Animation.mp4";

const Land = () => {
  return (
    <div className="container-fluid main_pg">
      <video
        width="100%"
        loop={true}
        autoPlay="autoPlay"
        muted
        src={index_im}
        type="video/mp4"
      />
      <div className="row justify-content-center mt-5 ssss align-items-center">
        <div className="col-md-6 col-sm-8 col-10 text-center text-white">
          <h2> $STATE Staking</h2>
          <p>
            Staking $STATE ERC-20 Governance Token gives our community the power
            to earn rewards on their holdings. Start staking in just a couple of
            clicks and automatically earn rewards. Instantly unstake at any time
            with no time-lock!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Land;
