let Environment = {
    // Main Net Contracts
    token: '0x00C2999c8B2AdF4ABC835cc63209533973718eB1',
    staking: '0x1502Fb51A6Bf1884b33DCdA38958E8C1E818146E',

    // Test Net Contracts
    // token: '0x78d0B340639aab3b200E7eBE38e701930e8850D4',
    // staking with seconds
    // staking: '0xd84e76e2808c405721F777822998aeA1f2f5Fa33',
    // token with days apy
    // staking: "0x6453aA0B153B4bf1CB1b7ccDe283b771a779657A"
};
export default Environment;