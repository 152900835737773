import { useEffect } from 'react'
import useAuth from './useAuth'
const ConnectorNames = {
  Injected: "injected",
  WalletConnect: "walletconnect",
  BSC: "bsc"
}
const useEagerConnect = () => {
  const { login } = useAuth()
  useEffect(() => {
    const item = localStorage.getItem("flag")
    const connectorId = localStorage.getItem("connectorId")
    console.log("==============>flag", item);
    if (item === 'true') {
      login(connectorId)
    }
    // login("injected")
  }, [login])
}

export default useEagerConnect