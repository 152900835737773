import web3NoAccount from './web3'
import stakingabi from '../utils/staking.json'
import tokinAbi from '../utils/tokenAbi.json'

const getContract = (abi, address, web3) => {
    const _web3 = web3 ?? web3NoAccount;
    // console.log('_web3',_web3);
    return new _web3.eth.Contract(abi, address)
}

export const getstakingAbi = (address, web3) => {
    return getContract(stakingabi, address, web3)
}

export const getTokenAbi = (address, web3) => {
    return getContract(tokinAbi, address, web3)
}
// export const daoProxyContract = (address, web3) => {
//     return getContract(BlueMoonDAOProxyAbi, address, web3)
// }

// export const exchangeContract = (address, web3) => {
//     return getContract(BlueMoonExchangeAbi, address, web3)
// }

// export const proxyRegistryContract = (address, web3) => {
//     return getContract(BlueMoonProxyRegistryAbi, address, web3)
// }

// export const tokenContract = (address, web3) => {
//     return getContract(BlueMoonTokenAbi, address, web3)
// }

// export const tokenTransferProxyContract = (address, web3) => {
//     return getContract(BlueMoonTokenTransferProxyAbi, address, web3)
// }