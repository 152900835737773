
import React, { useEffect, useState } from "react";
import "./css_files/style.scss";
import { Link } from "react-router-dom";

import { BidBalance } from '../../hooks/totalStaked'
import { StakedAmount } from '../../hooks/stakedAmount'
import { StakedReward } from '../../hooks/stakeReward'
import Approve from '../../hooks/approveAllowance'
import UserStaking from '../../hooks/staking'
import UserUnStaking from '../../hooks/unStaking'
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../hooks/useWeb3";
import Loader from "../../hooks/loader";
import { toast } from "react-toastify";

const Card = () => {


  const [tokenBalance, setTokenBalance] = useState(0)

  const { CurrentBlc } = BidBalance()


  const func = async () => {
    // console.log('askdfasl staked amount', account)
    let balance = await CurrentBlc()
    // console.log('askdfasl', balance)
    setTokenBalance(balance)
  }

  useEffect(() => {

    func()

  }, [tokenBalance])

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-lg-7 col-md-7 col-sm-6 col-12">
          <div className="card mx-auto">
            <div className="card_hd">
              <h2>Staking</h2>
            </div>
            <div className="card_bd">
              <div className="row justify-content-center">
                <div className="col-6">
                  <h1>16.18%</h1>
                  <p>Annual Percentage Yield (APY)</p>
                </div>
              </div>
              <hr />
            </div>
            <div className="pool_sz pb-3 text-center">
              <p>POOL SIZE Φ</p>
              <h3>{parseFloat(tokenBalance)?.toFixed(2)}</h3>
              <div className="d-flex justify-content-center">
                <Link to="/stake">
                  <button className="btn btn-dark my-3">Stake Now</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
