import { useCallback } from "react";
import {
  useWeb3React,
  UnsupportedChainIdError,
  Web3ReactProvider,
} from "@web3-react/core";
import { connectorsByName } from "../utils/web3React";
import { setupNetwork } from "../utils/wallet";

// import { Web3Provider } from "@ethersproject/providers";

const useAuth = () => {
  const { activate, deactivate } = useWeb3React();
  try {
    // const { toastError } = useToast()
    const login = useCallback((connectorID) => {
      const connector = connectorsByName[connectorID];
      if (connector) {
        activate(connector, async (error) => {
          if (error instanceof UnsupportedChainIdError) {
            const hasSetup = await setupNetwork();
            console.log("hasSetup onsale log", hasSetup);
            if (hasSetup) {
              activate(connector);
              console.log("hasSetup onsale log", hasSetup);
              console.log("hasSetup onsale log", "else", connector);
              console.log("hasSetup onsale log", "else", error);
            }
            return true;
          } else {
            // console.log("hasSetup onsale log", "else", error)
            connector.walletConnectProvider = undefined;
            //  toastError(error.name, error.message)
            return false;
          }
        });
        // return true;
      } else {
        console.log("hasSetup onsale log");
        return false;
        // toastError("Can't find connector", 'The connector config is wrong')
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { login, logout: deactivate };
  } catch (e) {
    console.log("hasSetup onsale log", e);
  }
};

export default useAuth;
