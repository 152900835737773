import React from "react";
import "./App.css";
import Nav from "./Components/Navbar/nav";
import Footer from "./Components/Footer/footer";
import Land from "./Components/Landing/land";
import Staking from "./Components/Staking/staking";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Card from "./Components/Cards/card";
import useEagerConnect from './hooks/useEagerConnect';
function App() {
  useEagerConnect()
  return (
    <>
      <Router>
        <Nav />
        <Switch>
          <Route path="/stake">
            <Staking />
          </Route>
          <Route path="/">
            <Land />
            <Card />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
